<template>
    <div class="login_success">
        登陆成功...
    </div>
</template>
<script>
export default {
    name:'loginSuccess',
    data(){
        return {
            code:''
        }
    },
    created(){
    },
    mounted() {
        console.log(this.$route)
        if(this.$route.query.code){
            this.code = this.$route.query.code;
            this.getWeixin(this.$route.query.code);
        }
    },
    methods:{
        getWeixin(code){
            this.$store.dispatch('login', {code}).then((datas) => {
                console.log("login==openid------->",datas)
                // datas.data.user.user_sn = 'user6018cd4a1512e'
                this.$storage.set('userData', datas.data.user)
                if(datas.status == 0){
                    this.$router.replace('index/placeOrder')
                }
            }).catch(() => {

            })
        }
    },
    watch:{
        $route:function(to,from){
            console.log("to",to)
            console.log("from",from)
            if(this.$route.query.code!=undefined){
                console.log("code",this.$route.query.code)
                // loginSuccess?code=081XYR0w3XHPTV2CMN2w3yaJpR0XYR0b&state=wyt
                // this.getWeixin(this.$route.query.code,this.$route.query.state);
            }
        }
    }
}
</script>
<style lang="scss">
    .login_success{
        padding: 6px;
        text-align: left;
    }
</style>